import React, { useCallback, useState } from "react"
import styled from "styled-components"

const Header = styled.h4`
  width: 100%;
  margin-top: 0;
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
  margin: var(--spacing-16) auto;
  max-width: 500px;
`

const Label = styled.label`
  width: 100%;
  margin-bottom: var(--spacing-1);
`

const Email = styled.input`
  flex: 1 1 70%;
  margin-right: var(--spacing-1);
  margin-bottom: var(--spacing-1);
  padding: var(--spacing-2);
  border-radius: 4px;
  border: 1px solid black;
  line-height: 1.5;
  :disabled {
    filter: brightness(75%);
  }
`

const Button = styled.button`
  flex: 1 1 96px;
  margin-bottom: var(--spacing-1);
  padding: var(--spacing-2);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  border-radius: 4px;
  color: var(--color-accent);
  cursor: pointer;
  transition: transform;
  :hover {
    filter: brightness(110%);
  }
  :active {
    filter: brightness(120%);
    transform: scale(0.99);
  }
  :disabled {
    filter: saturate(0%);
    cursor: auto;
  }
`

const Success = styled.div`
  width: 100%;
  color: var(--color-primary);
  font-size: var(--fontSize-1);
`

const Error = styled.div`
  width: 100%;
  color: var(--color-error);
  font-size: var(--fontSize-1);
`

const Notice = styled.p`
  font-size: var(--fontSize-0);
  margin: var(--spacing-2);
`

const action =
  "https://a7dfd550.sibforms.com/serve/MUIEAMrxT5TKIyeAI6j0yEwLdTSnMMTd9C_8BJmNHhCkdRoeqMnCz2yVwkm085zLH2y3aJdrDakgqKdrH-eyt7fg6lF-aZlvRsM-VN8gv3mOpVJVCUjLZOHj74C3_DOiSew71F03caDYK1fXVH7x-lqewx-aI-D6zimgzYffjhEt-IgR2rMwYkUkkqunxf5P0AqnCe4AuhvAGevi?isAjax=1"

const subscribeToNewsletter = async form =>
  fetch(action, {
    method: "POST",
    body: new FormData(form),
    headers: { Accept: "application/json" },
  })
export const EmailSignupForm = () => {
  const [status, setStatus] = useState("waiting")
  const [hasFocused, setHasFocused] = useState(false)
  const onEmailChange = useCallback(() => {
    setStatus("waiting")
  }, [setStatus])
  const onFocus = useCallback(() => {
    setHasFocused(true)
  }, [setHasFocused])
  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      setStatus("loading")
      subscribeToNewsletter(e.target)
        .then(() => {
          setStatus("success")
        })
        .catch(e => {
          setStatus("error")
          console.error(e)
        })
    },
    [setStatus]
  )
  return (
    <Form onSubmit={onSubmit}>
      <Header>Get new posts in your inbox</Header>
      <Label htmlFor="email">Email Address:</Label>
      <Email
        id="email"
        type="email"
        name="EMAIL"
        required
        placeholder="john.doe@email.com"
        onFocus={onFocus}
        onChange={onEmailChange}
        disabled={status === "loading"}
      />
      <input type="hidden" name="OPT_IN" value="1" />
      <Button type="submit" disabled={status === "loading"}>
        Subscribe
      </Button>
      {status === "success" ? (
        <Success>
          Success! Please check your email to confirm your subscription.
        </Success>
      ) : null}
      {status === "error" ? (
        <Error>Something went wrong. Please try again later!</Error>
      ) : null}
      {hasFocused ? (
        <>
          <Notice>
            You may unsubscribe at any time using the link in the newsletter.
          </Notice>
          <Notice>
            This site uses Sendinblue as a marketing platform. By clicking
            "Subscribe" to submit this form, you acknowledge that the
            information you provided will be transferred to Sendinblue for
            processing with their{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.sendinblue.com/legal/termsofuse/"
            >
              terms of use.
            </a>
          </Notice>
        </>
      ) : null}
    </Form>
  )
}
